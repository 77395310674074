@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #fe0000;
  border-right: 16px solid #fe0000;
  border-bottom: 16px solid #0e4b84;
  border-left: 16px solid #0e4b84;
  border-radius: 13%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.error-message {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0e4b84;
}
