.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  margin: 0 auto !important;
  color: black;
  padding: 20px;
  background: #0e4b84;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  position: relative;
  transition: all 0.5s ease-in-out;
  display: block !important;
  height: auto;
}

.modal-video {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-video video {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.modal-ftr {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-btn {
  background-color: #bd1c26;
  border-radius: 33px;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 329px;
  height: 59px;
  opacity: 0px;
}

.modal-btn a:hover {
  background-color: #e80716;
}
.modal-close {
  position: absolute;
  top: 0px;
  right: 1px;
  background-color: #0e4b84; /* Red background color */
  border: none;
  font-size: 2rem; /* Larger font size */
  color: white;
  cursor: pointer;
  border-radius: 50%; /* Make it circular */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-close:before {
  content: '\00d7'; /* Unicode for the multiplication sign (×) */
}

@media (max-width: 600px) {
  .modal {
    width: 95%;
    padding: 15px;
  }

  .modal-btn a {
    padding: 8px 16px;
  }

  .modal-close {
    font-size: 1rem;
  }
}

.modal-btn a:hover,
.modal-btn a:visited,
.modal-btn a:focus,
.modal-btn a:active {
  color: white !important;
  text-decoration: none !important;
}
.modal-nav-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  letter-spacing: 2px;
  font-weight: bold;
  border-radius: 33px;
  color: white;
}
