.logo-size {
  width: 230px;
  height: 100px;
  padding-bottom: 10px;
}

.navbar-container {
  top: 0;
  width: 100%;
  transition: top 0.3s ease-in-out;
  z-index: 1000; /* Make sure it's on top of other elements */
}

.navbar-container.hidden {
  position: relative;
  top: 0px; /* Adjust this value to hide the navbar completely */
}

.navbar-container.visible {
  position: relative;

  top: 0px;
}

.navbar {
  background-color: #ffff !important;
  padding: 0 !important;

  position: fixed;
  width: 100%;
  box-shadow: 0px 2px 8px #0e4b84;
  z-index: 1000;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
}

.navbar ul li {
  display: inline;
}

.navbar a {
  text-decoration: none !important;
  display: block;
  font-size: 5px;
  padding-left: 25px !important;
  padding-right: 25px !important;
  color: #0e4b84;
  font-weight: 500;
}

.navbar a:hover {
  color: #fe0000;
}

.navbar-spacing {
  margin-left: 22% !important;
}

.underline-hover {
  position: relative;
  color: #fe0000;
  text-decoration: none;
  transition: color 0.3s;
  text-decoration: none;
}

.underline-hover::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #fe0000;
  transition: width 0.3s;
}

.underline-hover:hover::after {
  width: 97%;
}

.btn-outline-success {
  background-color: #0e4b84;
  border-color: #0e4b84;
  color: #ffff;
}

.btn-outline-success:hover {
  background-color: #fe0000 !important;
  border-color: #fe0000;
}

.btn-outline-success a {
  color: white;
}

.btn-outline-success a:hover,
.btn-outline-success a:visited,
.btn-outline-success a:focus,
.btn-outline-success a:active {
  color: white;
}

/* Media query for screens 2560px wide */
@media only screen and (max-width: 2560px) {
  .navbar-container.hidden {
    top: -100px; /* Adjust this value to hide the navbar completely */
  }

  .navbar a {
    font-size: 15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .navbar-spacing {
    margin-left: 15% !important;
  }

  .btn-outline-success {
    padding-top: 7px;
    padding-bottom: 7px;
    width: 210px;
    margin-right: 2%;
    border-radius: 40px;
    font-size: 20px;
  }
}

/* Media query for screens 1725px wide */
@media only screen and (max-width: 1725px) {
  .navbar a {
    font-size: 16px !important;
  }

  .navbar-spacing {
    margin-left: 6% !important;
  }
}

@media only screen and (max-width: 1590px) {
  .navbar a {
    font-size: 14px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .btn-outline-success {
    width: 170px;
  }
}

/* Media query for screens 1484px wide */
@media only screen and (max-width: 1484px) {
  .logo-size {
    width: 200px;
    height: 88px;
  }

  .navbar-container.hidden {
    top: -85px; /* Adjust this value to hide the navbar completely */
  }

  .navbar-spacing {
    margin-left: 4% !important;
  }
}

/* Media query for screens 1404px wide */
@media only screen and (max-width: 1405px) {
  .navbar a {
    font-size: 14px !important;
  }

  .btn-outline-success {
    padding-top: 5px;
    padding-bottom: 6px;
    width: 170px;
  }
}
@media only screen and (max-width: 1331px) {
  .navbar a {
    font-size: 14px !important;
  }
}

/* Media query for screens 1329px wide */
@media only screen and (max-width: 1329px) {
  .navbar a {
    font-size: 13px !important;
  }
  .btn-outline-success {
    width: 130px;
  }
}
@media only screen and (max-width: 1329px) {
  .btn-outline-success {
    width: 163px;
  }
}
/* Media query for screens 1295px wide */
@media only screen and (max-width: 1289px) {
  .btn-outline-success {
    width: 167px;
  }
}
@media only screen and (max-width: 1286px) {
  .navbar a {
    font-size: 10px !important;
  }
  .btn-outline-success {
    width: 140px;
  }
}
/* Media query for screens 1233px wide */
@media only screen and (max-width: 1233px) {
  .navbar a {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .navbar-spacing {
    margin-left: 4% !important;
  }
}

/* Media query for screens 1160px wide */
@media only screen and (max-width: 1160px) {
  .navbar a {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .navbar-spacing {
    margin-left: 2% !important;
  }

  .logo-size {
    width: 150px;
    height: 66px;
  }

  .navbar-spacing {
    margin-left: 0% !important;
  }
}

@media only screen and (max-width: 1109px) {
  .btn-outline-success {
    width: 130px !important;
  }
  .navbar a {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .navbar-container.hidden {
    top: -65px; /* Adjust this value to hide the navbar completely */
  }

  .navbar-spacing {
    margin-left: 1% !important;
  }

  .btn-outline-success {
    padding-top: 6px;
    padding-bottom: 6px;
    width: 90px;
    margin-right: 3%;
    font-size: 12px !important;
  }
}

.scroll-progress-tracking-container {
  position: fixed;
  width: 100%;
  height: 10px;
  z-index: 1000;
}

.current-progress-bar {
  position: fixed;
  height: 8px;
  background: #fe0000;
  width: 0%;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
@media only screen and (max-width: 1045px) {
  .btn-outline-success {
    width: 130px;
  }
  .navbar a {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .btn-outline-success {
    width: 135px !important;
  }
  .navbar a {
    font-size: 11px !important;
  }
}

/* Media query for screens 1109px wide */

/* Media query for screens 999px wide */
@media only screen and (max-width: 999px) {
  .navbar a {
    font-size: 11px !important;
  }
}

@media only screen and (max-width: 991px) {
  .btn-outline-success {
    width: 160px !important;
  }
  .navbar a {
    font-size: 15px !important;
  }
  .main-btn {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 992px) {
  .btn-outline-success {
    width: 160px !important;
  }
}
/* Media query for screens 768px wide */
@media only screen and (max-width: 768px) {
  .btn-outline-success {
    margin-right: 1%;
  }

  .navbar-container.hidden {
    top: -0px; /* Adjust this value to hide the navbar completely */
  }
}
