@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: 'Nunito Sans', 'Poppins', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.booking-required {
  color: #fe0000 !important;
}
.help_icons {
  float: right;
  height: 25px;
  width: 25px;
}

.field_details {
  position: absolute;
  right: 30px;
  bottom: 44px;
  /* transform: translateX(9%) translateY(-99%); */
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  z-index: 10;
}
